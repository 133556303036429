<template>
  <div>
    <div style="color: white">{{ wb }}</div>
    <Divider />
    <Editor v-model="wb" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      wb: null,
    };
  },
};
</script>

<style>
</style>